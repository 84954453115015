.pageContainer {
  display: flex;
}
.email {
  margin-bottom: 16px;
}
.leftSide {
  width: 50vw;
  min-height: 100vh;
  background-image: linear-gradient(
      180deg,
      rgba(35, 54, 124, 0) 0%,
      #223b93 100%
    ),
    url("./assets/checkout-image.jpeg");
  background-size: cover;
  position: relative;
}
.logoContainer {
  background-color: #223b93;
  padding: 36px 18px;
  border-radius: 8px;
  margin: auto;
}
.textContainer {
  bottom: 100px;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
  position: absolute;
}
.text,
.subText {
  color: #fff;
  font-family: "Inter";
  margin: 0;
  padding: 0;
}
.text {
  font-weight: 700;
  font-size: 28px;
}
.subText {
  font-size: 18px;
  margin-top: 8px;
  line-height: auto;
  font-weight: 400;
}
.span {
  color: #93ce38;
}
.amountInfoContainer {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  /* border-bottom: 1px solid gray; */
  padding: 16px 24px;
  box-shadow: -5px -2px 37px 0px rgba(227, 227, 227, 0.75);
  -webkit-box-shadow: -5px -2px 37px 0px rgba(227, 227, 227, 0.75);
  -moz-box-shadow: -5px -2px 37px 0px rgba(227, 227, 227, 0.75);
  /* background-color: blue; */
  width: 80%;
}
.title {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}
.amount {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.left,
.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 850px) {
  .pageContainer {
    flex-direction: column;
  }
  .leftSide {
    width: 100vw;
    height: 50vh;
    min-height: unset;
  }
  .text {
    bottom: 10px;
    font-size: 24px;
  }
  .textContainer {
    bottom: 70px;
  }
}
