.pageContainer {
  background-color: #223b93;
  min-height: 100vh;
  width: 100vw;
}
.pageTitle {
  color: white;
  font-weight: 700;

  text-align: center;
}
.blueLogo {
  width: 150px;
}
.content {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 56px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.animation {
  height: "100%";
  width: "100%";
}
.cardContainer {
  width: 30vw;
  padding: 36px 24px;
  border-radius: 8px;
  background-color: white;
}

.logoContainer {
  background-color: #223b93;
  padding: 16px 8px;
  border-radius: 8px;
  margin: auto;
  display: flex;
  justify-content: center;
}
.message {
  font-weight: 600;
  font-size: 16px;
}
.caveat {
  font-weight: 500;
  font-size: 12px;
}
.transactionDetailsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 48px;
  row-gap: 18px;
  margin-top: 24px;
}
.title,
.detail {
  margin: 0;
  padding: 0;
}
.title {
  font-weight: 700;
  font-size: 16px;
  font-family: "Inter";
}
.detail {
  font-weight: 500;
  font-size: 14px;
  margin-top: 4px;
}
.bottom {
  margin-top: 36px;
}
.issues {
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  margin: 0;
}
.contact {
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-top: 4px;
}
.email {
  color: #223b93;
  font-weight: 600;
}

@media screen and (min-width: 480px) and (max-width: 980px) {
  .content {
    flex-direction: column;
    gap: 54px;
  }
  .cardContainer {
    width: 50vw;
  }
}

@media screen and (max-width: 480px) {
  .cardContainer {
    width: 80vw;

    margin-bottom: 18px;
  }
  .content {
    flex-direction: column;
    gap: 36px;
  }
}

@media screen and (max-width: 340px) {
  .transactionDetailsContainer {
    grid-template-columns: 1fr;
  }
}
